import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Slider from "react-slick";

const settings = {
  arrows: false,
  dots: false,
  infinite: true,
  swipe: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  centerMode: true,
  centerPadding: "10%",
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const Clienti = () => {
  const {
    clienti: { clienti },
  } = useStaticQuery(graphql`
    {
      clienti: clientiJson {
        clienti {
          name
          image {
            childImageSharp {
              original {
                width
                height
              }
              gatsbyImageData(layout: CONSTRAINED, quality: 100, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);
  return (
    <section className="section section--black section--clienti">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section__title">Clienti</div>
          </div>
        </div>
      </div>
      <Slider {...settings} className="slider-clienti">
        {clienti.map((item) => (
          <div key={item.name} className="slider-clienti__item">
            <div className="slider-clienti__square">
              <GatsbyImage
                image={getImage(item.image)}
                loading="eager"
                alt={item.name}
                style={{
                  maxWidth: item.image.childImageSharp.original.width,
                  maxHeight: item.image.childImageSharp.original.height,
                }}
              />
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default Clienti;
