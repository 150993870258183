import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";

import Clienti from "../components/clienti";
import AriadneGroupNews from "../components/ariadne-group-news";
import SliderCompetence from "../components/slider-competence";
import ProgettiSlider from "../components/progetti-slider";

import SquareBlueWhite from "../svg/square-blue-white.svg";

const hero = [
  {
    className: "hero--big",
    title: "We are Ariadne",
    subject: "Human driven technology",
    description: "Specializzati nello sviluppo di piattaforme digitali evolute",
  },
];

const IndexPage = ({ data, location }) => {
  const { progetti, ogimage } = data;

  return (
    <Layout location={location} hero={hero}>
      <Seo
        title={`Tecnologia Human Driven`}
        description={`Da oltre 25 anni specializzati nello sviluppo di piattaforme digitali`}
        img={ogimage}
      />

      <section className="section section--how-to">
        <SquareBlueWhite className="square-blue-white d-none d-lg-block" />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="box-how-do">
                <div className="row">
                  <div className="col-12 col-lg-10 offset-lg-2 col-xl-7 offset-xl-5">
                    <div className="box-how-do__desc">
                      <p>
                        <strong className="color-primary">Ariadne</strong> è una realtà
                        specializzata nella realizzazione di piattaforme digitali evolute attraverso
                        l’impiego di tecnologie e metodologie in continuo sviluppo
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-2 col-xl-5">
                    <span className="d-inline-block font-small text-uppercase color-primary mb-3 mb-lg-0">
                      Siamo specializzati in
                    </span>
                  </div>
                  <div className="col-12 col-lg-10 col-xl-7">
                    <ul className="box-how-do__list">
                      <li className="box-how-do__item">Corporate &amp; Consumer Portal</li>
                      <li className="box-how-do__item">Cloud Native Apps</li>
                      <li className="box-how-do__item">Cloud Service Management</li>
                      <li className="box-how-do__item">Digitalizzazione di Processi</li>
                      <li className="box-how-do__item">Intranet &amp; Digital Workplace</li>
                      <li className="box-how-do__item">Architetture a Microservizi</li>
                      <li className="box-how-do__item">Ecommerce</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SliderCompetence />

      <ProgettiSlider items={progetti} />

      <Clienti />

      <AriadneGroupNews />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    progetti: allContentfulCliente(filter: { node_locale: { eq: "it" } }, limit: 10) {
      nodes {
        nome
        sottotitolo
        logoBianco {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, placeholder: BLURRED)
        }
        immagineSfondo {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, placeholder: BLURRED)
        }
      }
    }
    ogimage: file(relativePath: { eq: "share-img.png" }) {
      childImageSharp {
        gatsbyImageData(height: 630, layout: CONSTRAINED)
      }
    }
  }
`;
