import React from "react";

import Arrow from "../svg/square-arrow.svg";

const ariadneGroupNews = [
  {
    title: "Nuovi progetti",
    intro: "ariadne group",
    link: "https://www.ariadnegroup.it/progetti/",
  },
  {
    title: "Case studies",
    intro: "ariadne group",
    link: "https://www.ariadnegroup.it/blog/#case-study",
  },
  {
    title: "Blog",
    intro: "ariadne group",
    link: "https://www.ariadnegroup.it/blog/",
  },
  {
    title: "Jobs",
    intro: "ariadne group",
    link: "https://www.ariadnegroup.it/jobs/",
  },
];

const AriadneGroupNews = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col-12 text-lg-end">
            <div className="section__title section__title--group-news">
              Ariadne Group <span className="d-inline-block">News</span>
            </div>
          </div>
        </div>
      </div>
      {ariadneGroupNews.map((item, i) => (
        <div key={i}>
          <a
            href={item.link}
            title={item.title}
            target="_blank"
            rel="noopener noreferrer"
            data-content={item.title}
            className="box-btn"
          >
            <span className="box-btn__intro" data-content={item.intro}>
              {item.intro}
            </span>
            {item.title}
            <Arrow className="box-btn__icon" />
          </a>
        </div>
      ))}
    </section>
  );
};

export default AriadneGroupNews;
