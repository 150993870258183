import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import { Link } from "./link";

import SquareProgetti from "../svg/square-progetti.svg";

const settings = {
  arrows: false,
  dots: true,
  infinite: false,
  swipe: true,
  slidesToShow: 2,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const ProgettiSlider = ({ items }) => {
  return (
    <>
      <section className="section section--primary section--progetti">
        <SquareProgetti className="square" />
        <div className="container">
          <div className="row">
            <div className="col-12 text-xl-end">
              <div className="section__title section__title--progetti-liferay">
                Progetti <span>Liferay</span>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper-slider-progetti">
          <div className="container-slider-progetti">
            <Slider {...settings} className="slider-progetti">
              {items.nodes.map((progetto, i) => (
                <div key={i}>
                  <div className="box-progetto">
                    <div className="box-progetto__bg d-flex justify-content-center align-items-center">
                      {progetto.immagineSfondo && (
                        <GatsbyImage
                          image={getImage(progetto.immagineSfondo)}
                          alt={progetto.nome}
                          className="img-bg"
                        />
                      )}
                      <div className="box-progetto__logo d-flex justify-content-center align-items-center">
                        {progetto.logoBianco && (
                          <GatsbyImage
                            image={getImage(progetto.logoBianco)}
                            alt={progetto.nome}
                            className="box-progetto__img"
                          />
                        )}
                      </div>
                    </div>
                    <div className="box-progetto__title">{progetto.nome}</div>
                    {progetto.sottotitolo && (
                      <div className="box-progetto__desc">{progetto.sottotitolo}</div>
                    )}
                  </div>
                </div>
              ))}
            </Slider>
            <Link to="/progetti/" title="Vai alla lista completa" className="btn-link">
              Vedi tutti
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProgettiSlider;
